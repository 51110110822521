<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-800px">
            <div>
                <p class="title">경력추가</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-list">
                    <colgroup>
                        <col width="80">
                        <col width="*">
                        <col width="90">
                        <col width="90">
                        <col width="100">
                        <col width="100">
                        <col width="100">
                        <col width="70">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>외/내부</th>
                            <th>회사명</th>
                            <th>입사일</th>
                            <th>퇴사일</th>
                            <th>경력년수</th>
                            <th>경력인정여부</th>
                            <th>인정경력</th>
                            <th>직위</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ pop.info.ctype }}</td>
                            <td>{{ pop.info.title }}</td>
                            <td>{{ pop.info.sdate }}</td>
                            <td>{{ pop.info.edate }}</td>
                            <td>{{ pop.info.period_txt }}</td>
                            <td>{{ pop.info.is_use_txt + '(' + pop.info.is_use_rate + '%)' }}</td>
                            <td>{{ pop.info.available_career_txt }}</td>
                            <td>{{ pop.info.position }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="tbl-light-view mt-10">
                    <colgroup>
                        <col width="90">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>담당업무</th>
                            <td>
                                <div class="preline">{{ pop.info.maintask }}</div>
                            </td>
                        </tr>
                        <tr>
                            <th>기타사항</th>
                            <td>
                                <div class="preline">{{ pop.info.etc }}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>            
            <div class="layer-btn mt-10 w-100per dp-table">
                <button class="btn-default float-left" @click="pop.onClose()">닫기</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'CareerViewPopup',
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            idx  : 0,
            info : {
                period_txt : "0년 0월",
                is_use_txt : "인정",
                is_use_rate : "100",
                available_career_txt : "0년 0월"
            },

            doSearch : () => {
                let params = {
                    idx : pop.idx
                };
                axios.get("/rest/mypims/getHQCareerInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;
                    } else {
                        console.log(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx = props.idx;
            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>