<template>
    <div v-if="view.isLoaded==true" class="con-wrap">
        <CareerAddPopup v-if="pop.show_career_add" :idx="pop.idx" @close="pop.hideAddCareer"></CareerAddPopup>
        <CareerViewPopup v-if="pop.show_career_view" :idx="pop.idx" @close="pop.show_career_view=false"></CareerViewPopup>

        <CarrotTitle :title="view.info.ename+'('+view.info.kname+') 인사카드'"></CarrotTitle>
        <div class="board">
            <div class="mt-40">

                <div class="tabDefult-col">
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab1-idx', params : { idx:view.idx }}"><button class="tab-default on">인적사항</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab2-idx', params : { idx:view.idx }}"><button class="tab-default">인사History</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab3-idx', params : { idx:view.idx }}"><button class="tab-default">평가</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab4-idx', params : { idx:view.idx }}"><button class="tab-default">휴가</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab5-idx', params : { idx:view.idx }}"><button class="tab-default">교육</button></router-link>
                    <!-- <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab6-idx', params : { idx:view.idx }}"><button class="tab-default">권한</button></router-link> -->
                </div>

                <div>
                    
                    <div class="dp-table w-100per">

                        <div class="float-left w-20per pr-10 txt-center">
                            <div class="photo-box mb-20">
                                <span>프로필<br>이미지<br>영역</span>
                                <img class="my-profile" src="@/assets/img/noimage_1x1.png" :style="{ 'backgroundImage':'url('+view.info.personalimg+')' }" :alt="view.info.ename" @error="view.setAltImg">
                            </div>
                            <button class="btn-default dp-inblock up-photo-btn" v-if="view.info.state!='QUIT'">
                                <span>사진변경</span>
                                <input type="file" class="pointer up-photo-file " ref="photoimg" @change="mod.changePhoto" accept="image/*">
                            </button>
                        </div>

                        <div class="float-right w-80per pl-10">
                            <table class="table-row">
                                <colgroup>
                                    <col width="120">
                                    <col width="*">
                                    <col width="120">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>이름</th>
                                        <td class="po-relative">
                                            <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                            <input type="text" class="w-200px" v-model.trim="view.info.kname">
                                        </td>
                                        <th>영문이름</th>
                                        <td class="po-relative">
                                            <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                            <input type="text" class="w-200px" v-model.trim="view.info.ename">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>주민등록번호</th>
                                        <td class="po-relative">
                                            <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                            <div>
                                                <label><input type="radio" id="number_ra1" name="number_ra" value="N" v-model="view.info.is_foreigner"><span class="ml-5 mr-20"> 내국인</span></label> 
                                                <input type="text" class="w-80px" v-if="view.info.is_foreigner=='N'" v-model="view.info.ssn1" maxlength="6">
                                                <span v-if="view.info.is_foreigner=='N'"> - </span>
                                                <input type="text" class="w-80px" v-if="view.info.is_foreigner=='N'&&view.show_secret==false" v-model.number="view.info.ssn2" maxlength="7">
                                                <input type="text" class="w-80px" v-if="view.info.is_foreigner=='N'&&view.show_secret==true" v-model="view.secret_ssn2" readonly>
                                                <button type="button" class="btn-default h-30px dp-inblock ml-5" @click="view.showSSN2">조회</button>
                                            </div>
                                            <div class="mt-5">
                                                <label><input type="radio" id="number_ra2" name="number_ra" value="Y" v-model="view.info.is_foreigner"><span class="ml-5 mr-20"> 외국인</span></label> 
                                                <input type="text" class="w-110px" v-if="view.info.is_foreigner=='Y'" v-model.trim="view.info.ssn1" placeholder="외국인등록번호" maxlength="50">
                                            </div>
                                        </td>
                                        <th>성별</th>
                                        <td class="po-relative">
                                            <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                            <label><input type="radio" id="MY_ra1" name="MY_ra" value="M" v-model="view.info.gender"><span class="ml-5 mr-20"> 남</span></label>
                                            <label><input type="radio" id="MY_ra2" name="MY_ra" value="F" v-model="view.info.gender"><span class="ml-5 mr-20"> 여</span></label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>생년월일</th>
                                        <td class="po-relative">
                                            <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                            <label><input type="radio" id="Bir_ra1" name="Bir_ra" value="N" v-model="view.info.is_lunar"><span class="ml-5 mr-20"> 양력</span></label>
                                            <label><input type="radio" id="Bir_ra2" name="Bir_ra" value="Y" v-model="view.info.is_lunar"><span class="ml-5 mr-20"> 음력</span></label>
                                            <div class="mt-5">
                                                <CarrotDatePicker v-model="view.info.birth" class="calendar_birth w-110px float-left"></CarrotDatePicker>
                                            </div>
                                        </td>
                                        <th>결혼기념일</th>
                                        <td class="po-relative">
                                            <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                            <div>
                                                <label><input type="radio" id="Mar_ra1" name="Mar_ra" value="N" v-model="view.info.is_married"><span class="ml-5 mr-20"> 미혼</span></label>
                                            </div>
                                            <div class="mt-5">
                                                <label class="float-left"><input type="radio" id="Mar_ra2" name="Mar_ra" value="Y" v-model="view.info.is_married"><span class="ml-5 mr-20"> 기혼</span></label>
                                                <CarrotDatePicker v-if="view.info.is_married=='Y'" v-model="view.info.married_date" class="calendar_married w-110px"></CarrotDatePicker>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>연락처</th>
                                        <td class="po-relative">
                                            <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                            <input type="text" class="w-200px" maxlength="20" v-model.trim="view.info.phone">
                                        </td>
                                        <th>비상연락망</th>
                                        <td class="po-relative">
                                            <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                            <input type="text" class="w-200px" maxlength="20" v-model.trim="view.info.emergency_tel">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>개인 Email</th>
                                        <td class="po-relative">
                                            <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                            <input type="text" class="w-200px" maxlength="20" v-model.trim="view.info.personal_email">
                                        </td>
                                        <th>내선번호</th>
                                        <td class="po-relative">
                                            <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                            <input type="text" class="w-200px" maxlength="20" v-model.trim="view.info.mp3code">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>   
                    </div> 
                    
                    <table class="table-row mt-10">
                        <colgroup>
                            <col width="120">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>주소</th>
                                <td class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <carrot-address v-model:zipcode.sync="view.info.zipcode" v-model:address.sync="view.info.addr"></carrot-address>
                                    <input type="text" class="w-100per mt-5" v-model.trim="view.info.addr_sub" maxlength="50" placeholder="상세주소를 입력하세요.">
                                </td>
                            </tr>
                            <tr>
                                <th>비상주소</th>
                                <td class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <input type="text" class="w-100per" v-model.trim="view.info.emergency_addr" maxlength="50">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="mt-50 mb-20 block-title">이력서</div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>이력서</th>
                                <td class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <CarrotFileUploader ref="resume" @change="mod.upResume"></CarrotFileUploader>
                                    <div v-if="view.info.resume_file" class="pt-05">
                                        <a :href="view.info.resume_file" target="_blank" class="float-left"><span>이력서 다운로드</span></a>
                                        <button type="button" class="btn-default float-left ml-10" @click="del.delResume">삭제</button>
                                        <div class="clear"></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="mt-50 mb-20">
                        <div class="block-title float-left mt-10">학력사항</div>
                        <button class="btn-default float-right" v-if="view.info.state!='QUIT'" @click="mod.addEducation()">+ 학력 추가</button>
                        <div class="clear"></div>
                    </div>
                    <div class="po-relative">
                        <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                        <table class="table-col">
                            <colgroup>
                                <col width="270">
                                <col width="250">
                                <col width="140">
                                <col width="220">
                                <col width="220">
                                <col width="100">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>기간</th>
                                    <th>학교명</th>
                                    <th>구분</th>
                                    <th>전공</th>
                                    <th>복수전공</th>
                                    <th>학점</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(irow, i) in view.education.list" :key="i">
                                    <td :sdate="irow.sdate" :edate="irow.edate">
                                        <input type="text" v-model="irow.sdate" class="w-110px float-left">
                                        <span class="float-left ml-10 mr-10">~</span>
                                        <input type="text" v-model="irow.edate" class="w-110px float-left">
                                    </td>
                                    <td>
                                        <input type="text" class="w-100per" v-model.trim="irow.title">
                                    </td>
                                    <td>
                                        <select v-model="irow.state" class="w-100per">
                                            <option value="">선택</option>
                                            <option value="졸업">졸업</option>
                                            <option value="재학중">재학중</option>
                                            <option value="휴학중">휴학중</option>
                                            <option value="중퇴">중퇴</option>
                                            <option value="수료">수료</option>
                                            <option value="이수">이수</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" class="w-100per" v-model.trim="irow.major">
                                    </td>
                                    <td>
                                        <input type="text" class="w-100per" v-model.trim="irow.major2">
                                    </td>
                                    <td>
                                        <input type="text" class="w-100per" v-model.trim="irow.degree">
                                    </td>
                                </tr>
                                <tr v-if="view.education.total==0">
                                    <td colspan="6">입력한 학력정보가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="mt-50 mb-20">
                        <div class="block-title float-left mt-10">경력사항 <span class="tip-c2 bold">(총 경력 {{ view.career.total_career_txt }})</span></div>
                        <button class="btn-default float-right" v-if="view.info.state!='QUIT'" @click="pop.addCareer()">+ 경력 추가</button>
                        <div class="clear"></div>
                    </div>
                    <div class="po-relative">
                        <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                        <table class="table-col">
                            <colgroup>
                                <col width="60">
                                <col width="240">
                                <col width="100">
                                <col width="100">
                                <col width="120">
                                <col width="120">
                                <col width="120">
                                <col width="120">
                                <col width="100">
                                <col width="100">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>회사명</th>
                                    <th>입사일</th>
                                    <th>퇴사일</th>
                                    <th>경력년수</th>
                                    <th>경력인정여부</th>
                                    <th>인정경력</th>
                                    <th>직위</th>
                                    <th>담당업무</th>
                                    <th>삭제</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(irow, i) in view.career.list" :key="i">
                                    <td>{{ irow.num }}</td>
                                    <td>{{ irow.title }}</td>
                                    <td>{{ irow.sdate }}</td>
                                    <td>{{ irow.edate }}</td>
                                    <td>{{ irow.period_txt }}</td>
                                    <td>{{ irow.is_use_txt }}({{ irow.is_use_rate }}%)</td>
                                    <td>{{ irow.available_career_txt }}</td>
                                    <td>{{ irow.position }}</td>
                                    <td>
                                        <span class="btn-view" @click="pop.showCareer(irow.idx)">보기</span>
                                    </td>
                                    <td>
                                        <button class="btn-default dp-inblock" @click="del.delCareer(irow.idx)">삭제</button>
                                    </td>
                                </tr>
                                <tr v-if="view.career.total==0">
                                    <td colspan="10">입력한 경력정보가 없습니다.</td>
                                </tr>
                                <tr>
                                    <td colspan="4">외부경력</td>
                                    <td colspan="2">{{ view.career.external.total_txt }}</td>
                                    <td colspan="4">{{ view.career.external.available_txt }}</td>
                                </tr>
                                <tr>
                                    <td colspan="4">내부근속</td>
                                    <td colspan="2">{{ view.career.internal.total_txt }}</td>
                                    <td colspan="4">{{ view.career.internal.available_txt }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="mt-50 mb-20">
                        <div class="block-title float-left mt-10">자격증 및 어학능력</div>
                        <button class="btn-default float-right" v-if="view.info.state!='QUIT'" @click="mod.addCertificate()">+ 항목 추가</button>
                        <div class="clear"></div>
                    </div>
                    <div class="po-relative">
                        <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                        <table class="table-col">
                            <colgroup>
                                <col width="180">
                                <col width="550">
                                <col width="140">
                                <col width="330">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>취득일자</th>
                                    <th>자격증 및 어학능력</th>
                                    <th>어학능력(점수)</th>
                                    <th>기타사항</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(irow, i) in view.certificate.list" :key="i">
                                    <td>
                                        <input type="text" v-model="irow.regdate" class="w-160px">
                                    </td>
                                    <td>
                                        <input type="text" class="w-100per" maxlength="50" v-model.trim="irow.title">
                                    </td>
                                    <td>
                                        <input type="text" class="w-100per txt-center" maxlength="10" v-model.trim="irow.score">
                                    </td>
                                    <td>
                                        <input type="text" class="w-100per" maxlength="50" v-model.trim="irow.etc">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <table class="table-col mt-50">
                        <colgroup>
                            <col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>해외연수 및 해외경험 ({{ view.info.overseas?view.info.overseas.length:0 }}/500)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <textarea class="w-100per h-100px" v-model.trim="view.info.overseas" @keydown="view.checkText('overseas')" @keyup="view.checkText('overseas')" maxlength="500"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table class="table-col mt-50">
                        <colgroup>
                            <col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>대외활동 ({{ view.info.professional_exp?view.info.professional_exp.length:0 }}/500)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <textarea class="w-100per h-100px" v-model.trim="view.info.professional_exp" @keydown="view.checkText('professional_exp')" @keyup="view.checkText('professional_exp')" maxlength="500"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="view.goList">목록</button>
                    <button class="btn-default float-right mt-30" v-if="view.info.state!='QUIT'" @click="mod.doSubmit">저장</button>
                    <div class="clear"></div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotAddress from '@/components/common/CarrotAddress.vue'
import CareerAddPopup from '@/components/popup/myPIMS/PersonalInfoList/CareerAddPopup.vue'
import CareerViewPopup from '@/components/popup/myPIMS/PersonalInfoList/CareerViewPopup.vue'
import Swal from 'sweetalert2'
import {useStore} from "vuex";


export default {
    layout:"myPIMS",
    components: {
        CareerAddPopup,
        CareerViewPopup,
        CarrotDatePicker,
        CarrotAddress
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast();
        const store  = useStore();

        const photoimg = ref(null);
        const resume   = ref(null);

        const pop = reactive({
            // Popup 노출 여부

            show_career_add  : false,
            show_career_view : false,

            addCareer : () => {
                pop.idx = view.idx;
                pop.show_career_add = true;
            },
            hideAddCareer : () => {
                pop.show_career_add = false;
                view.getCareer();
            },

            showCareer : (idx) => {
                pop.idx = idx;
                pop.show_career_view = true;
            },
        });

        const del = reactive({

            delResume : () => {
                let params = {
                    idx: view.idx
                };

                if( confirm("삭제하시겠습니까?") == true ){
                    axios.post("/rest/mypims/delResume", params).then((res) => {
                        if( res.data.err == 0 ){
                            view.info.resume_name = "";
                            view.info.resume_file = "";
                        } else {
                            alert(res.data.err_msg);
                        }
                    });
                }
            },

            delCareer : (idx) => {
                let params = {
                    idx: idx
                };

                if( confirm("삭제하시겠습니까?") == true ){
                    axios.post("/rest/mypims/delCareer", params).then((res) => {
                        if( res.data.err == 0 ){
                            view.getCareer();
                        } else {
                            alert(res.data.err_msg);
                        }
                    });
                }
            }
        });

        const mod = reactive({
            idx : 0,

            changePhoto : () => {
                // photo-img
                if(photoimg.value.files.length <= 0 ) {
                    return;
                }
                let f = photoimg.value.files[0];
                if( f.size > 5*1024*1024) {
                    toast.error("5메가 이상의 파일은 업로드 하실 수 없습니다.");
                    f = null;
                    return;
                }

                const reader = new FileReader();
                reader.onload = e => {
                    view.info.personalimg = e.target.result;
                }
                // reader가 이미지 읽도록 하기
                reader.readAsDataURL(f);

            },

            upResume : () => {
                console.log(resume.value);
            },

            addEducation : () => {
                view.education.list.push({
                    idx:0, sdate:"", edate:"", title:"", state:"", major:"", major2:"", degree:""
                });
            },

            addCertificate : () => {
                view.certificate.list.push({
                    idx:0, regdate:"", title:"", score:"", etc:""
                });
            },

            doSubmit : () => {

                if(store.state.pd_auth !=true){
                  Swal.fire({
                    title : '인사카드 상세',
                    text  : '수정 권한이 없습니다.'
                  });
                  return;
                }
                if(isNaN(view.info.ssn1)){
                  alert("주민번호는 숫자만 입력해주세요");
                  return;
                }
                let params = {
                    idx   : view.idx,
                    kname : view.info.kname, 
                    ename : view.info.ename, 
                    ssn1  : view.info.ssn1,
                    ssn2  : Number(view.info.ssn2),  
                    is_foreigner  : view.info.is_foreigner,
                    gender        : view.info.gender, 
                    birth         : view.info.birth,  
                    is_lunar      : view.info.is_lunar, 
                    is_married    : view.info.is_married,
                    married_date  : view.info.married_date, 
                    phone         : view.info.phone, 

                    emergency_tel  : view.info.emergency_tel, 
                    personal_email : view.info.personal_email,
                    mp3code        : view.info.mp3code,

                    zipcode        : view.info.zipcode,
                    addr           : view.info.addr,
                    addr_sub       : view.info.addr_sub,
                    emergency_addr : view.info.emergency_addr,

                    resume_name    : view.info.resume_name,
                    resume_file    : view.info.resume_file,

                    education_info   : JSON.stringify(view.education.list),
                    certificate_info : JSON.stringify(view.certificate.list),
                    career_info      : JSON.stringify(view.career.list),

                    // 추가입력사항
                    overseas         : view.info.overseas, 
                    professional_exp : view.info.professional_exp
                };


                if( params.kname == "" ){
                    toast.error("이름을 입력하세요.");
                    return ;
                }

                if( params.ename == "" || /^[A-Za-z ]/.test(params.ename) == false ){
                    toast.error("영문이름을 입력하세요.");
                    return ;
                }

                if( params.is_foreigner == "N" && ( params.ssn1.toString() == "" || params.ssn1.toString().length != 6 || /^[0-9]/.test(params.ssn1.toString()) == false || params.ssn2.toString() == "" || params.ssn2.toString().length != 7 || /^[0-9]/.test(params.ssn2.toString()) == false ) ){
                    toast.error("주민등록번호를 입력하세요.");
                    return ;
                }

                if( params.is_foreigner == "Y" && params.ssn1 == "" ){
                    toast.error("외국인등록번호를 입력하세요.");
                    return ;
                }

                if( params.gender == "" ){
                    toast.error("성별을 선택하세요.");
                    return ;
                }

                if( params.birth == "" && /^[0-9-]/.test(params.birth) == false && params.birth.length != 10 ){
                    toast.error("생년월일을 입력하세요.");
                    return ;
                }
                if( params.is_lunar == "" ){
                    toast.error("양력/음력을 선택하세요.");
                    return ;
                }

                if( params.is_married == "" ){
                    toast.error("결혼 유무를 선택하세요.");
                    return ;
                }
                if( params.is_married == "Y" && (params.married_date == "" || /^[0-9-]/.test(params.married_date) == false || params.married_date.length != 10 ) ){
                    toast.error("결혼기념일을 입력하세요.");
                    return ;
                }

                if( params.phone == "" ){
                    toast.error("연락처를 입력하세요.");
                    return ;
                }

                if( params.emergency_tel == "" ){
                    toast.error("비상연락망을 입력하세요.");
                    return ;
                }

                Swal.fire({
                    title : "인사카드",
                    text  : "저장하시겠습니까?",
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        var formData = new FormData();
                        Object.keys(params).forEach(function(key){
                            formData.append(key, params[key]);
                        });

                        if( photoimg.value.files.length > 0 ) {
                            formData.append("personalimg", photoimg.value.files[0]);
                        }

                        if(resume.value.file.filedata!==null) {
                            formData.append("resume", resume.value.file.filedata);
                        }

                        axios.post("/rest/mypims/modNewHQ", formData).then((res) => {
                            if( res.data.err == 0 ){
                                view.doSearch();
                                Swal.fire({
                                    title : "인사카드",
                                    text  : "저장하였습니다."
                                }).then(() => {
                                    // window.scrollTo(0,0);
                                });
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },
        });

        const view = reactive({
            isLoaded : false,

            idx : 0,
            defaultimg : "@/assets/img/avatar.png",
            show_secret : true,
            secret_ssn2 : "",

            info : { kname:"", ename:"", photoimg:"" },
            certificate : { list: [{ title:"" }], total: 0 },
            education   : { list: [{ title:"" }], total: 0 },
            career      : { list: [{ title:"" }], total: 0, external:{ total_txt:"" }, internal:{ total_txt:"" } },
            viewAuth : false,

            setAltImg(event) { 
                event.target.src = view.defaultimg;
            },

            goList : () => {
                if( confirm("입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?") == true ){
                    router.push({
                        name : "myPIMS-IncumbentManagementList"
                    });
                }
            },

            viewTab : (id) => {
                router.push({
                    name   : 'myPIMS-PersonalInfoList_tab' + id + '-idx',
                    params : { idx : view.idx }
                });
            },

            showSSN2 : () => {
                view.show_secret = !view.show_secret;
            },

            getCareer : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/mypims/getHQCareerList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.career      = res.data;
                    } else {
                        console.log(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : view.idx,
                  user_position: store.state.idx_position,
                  user_sector: store.state.idx_sector,
                  user_office: store.state.idx_office,
                  user_team: store.state.idx_team
                };

                axios.get("/rest/mypims/getHQInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;

                        view.show_secret = true;
                        if( view.info.ssn2 ){
                            view.secret_ssn2 = view.info.ssn2.substr(0, 1) + "******";
                        }

                        view.education   = res.data.education_info;
                        view.certificate = res.data.certificate_info;
                        view.career      = res.data.career_info;
                        view.isLoaded = true;

                        //인사카드-tab1 열람권한 체크 추가
                        view.viewAuth=false;
                        if(store.state.isMaster || (view.info.auth.dept && view.info.auth.position)) //마스터권한 또는 (부문/본부/팀+직급 체크)
                          view.viewAuth=true;

                        if(store.state.pd_auth)
                          view.viewAuth=true;

                        if(view.viewAuth==false){
                          router.push({ name : 'main' });
                          Swal.fire({
                            title : '인사카드 상세',
                            text  : '접근 권한이 없습니다.'
                          });
                          return;
                        }

                    } else {
                        console.log(res.data.err_msg);
                    }
                });
            },

            checkText : (txttype) => {
                switch(txttype){
                    case 'overseas':
                        view.info.overseas = view.info.overseas.substr(0, 500);
                        break;
                    case 'professional_exp':
                        view.info.professional_exp = view.info.professional_exp.substr(0, 500);
                        break;
                }
            }
        });

        // watch(() => view.info.overseas, (nVal, oVal) => {
        //     if(nVal != oVal) {
        //         if( nVal.length && nVal.length > 500 ){
        //             view.info.overseas = view.info.overseas.substr(0, 500);
        //         }
        //     }
        // });

        // watch(() => view.info.professional_exp, (nVal, oVal) => {
        //     if(nVal != oVal) {
        //         if( nVal.length && nVal.length > 500 ){
        //             view.info.professional_exp = view.info.professional_exp.substr(0, 500);
        //         }
        //     }
        // });

        onMounted(() => {
            // Mounted
            view.idx = route.params.idx?route.params.idx:0;

            if ( !view.idx ) {
                router.back(-1);
            }

            view.doSearch();
        });

        return {resume, photoimg, pop, del, mod, view};
    }
}
</script>

<style lang="scss" scoped>
.photo-box {
    position:relative;
    .my-profile {
        position:absolute;top:0;left:0;width:100%;height:100%;background-size:cover; background-position:center; background-repeat:no-repeat;
    }
}
.up-photo-btn {
    cursor:pointer; position:relative; z-index:1;
    span {
        cursor:pointer;
    }
    .up-photo-file {
        cursor:pointer;position:absolute; top:0; left:0; width:100%; height:100%; opacity:0.01; z-index:2;
    }
}
.calendar_married{
    position: relative;
}
.calendar_birth{
    position: relative;
}
</style>