<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-800px">
            <div>
                <p class="title">경력추가</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-list">
                    <colgroup>
                        <col width="90">
                        <col width="*">
                        <col width="120">
                        <col width="120">
                        <col width="70">
                        <col width="90">
                        <col width="70">
                        <col width="90">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>외/내부</th>
                            <th>회사명</th>
                            <th>입사일</th>
                            <th>퇴사일</th>
                            <th>경력년수</th>
                            <th>경력인정여부</th>
                            <th>인정경력</th>
                            <th>직위</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <select class="w-100per" v-model="pop.ctype">
                                    <option value="">선택</option>
                                    <option value="외부">외부</option>
                                    <option value="내부(퇴사)">내부(퇴사)</option>
                                    <option value="내부(재직)">내부(재직)</option>
                                </select>
                            </td>
                            <td>
                                <input type="text" class="w-100per" maxlength="50" v-model.trim="pop.title">
                            </td>
                            <td>
                                <input type="text" v-model="pop.sdate" class="w-110px float-left">
                            </td>
                            <td>
                                <input type="text"  v-model="pop.edate" class="w-110px float-left">
                            </td>
                            <td>{{ pop.period_txt }}</td>
                            <td>
                                <select class="w-100per" v-model="pop.is_use" @change="pop.doCalculate">
                                    <option value="">선택</option>
                                    <option value="N">비인정</option>
                                    <option value="S">유사(50%)</option>
                                    <option value="Y">인정(100%)</option>
                                </select>
                            </td>
                            <td>{{ pop.available_career_txt }}</td>
                            <td>
                                <input type="text" class="w-100per" maxlength="50" v-model.trim="pop.position">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="tbl-light-view mt-10">
                    <colgroup>
                        <col width="90">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>담당업무</th>
                            <td>
                                <textarea class="w-100per h-100px" v-model.trim="pop.maintask"></textarea>
                            </td>
                        </tr>
                        <tr>
                            <th>기타사항</th>
                            <td>
                                <textarea class="w-100per h-100px" v-model.trim="pop.etc"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>            
            <div class="layer-btn mt-10 w-100per dp-table">
                <button class="btn-default float-left" @click="pop.onClose">취소</button>
                <button class="btn-default float-right" @click="pop.doSubmit">등록</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'CareerAddPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            ctype : "",
            title : "",
            sdate : "",
            edate : "",
            period : 0,
            period_txt : "0년 0월",
            is_use : "",
            available_career : 0,
            available_career_txt : "0년 0월",
            position : "",
            maintask : "",
            etc : "",

            doCalculate: () => {
                if( pop.sdate && pop.edate ){
                    let ts1 = new Date(pop.sdate);
                    let ts2 = new Date(pop.edate);

                    let y1 = ts1.getFullYear();
                    let y2 = ts2.getFullYear();

                    let m1 = ts1.getMonth();
                    let m2 = ts2.getMonth();

                    let diff = ((y2 - y1) * 12) + (m2 - m1);

                    pop.period = diff;
                    pop.period_txt = Math.floor(diff/12)+"년 " + (diff%12)+"월";
                    if( pop.is_use == "Y" ) {
                        pop.available_career = diff;
                        pop.available_career_txt = Math.floor(pop.available_career/12)+"년 " + (pop.available_career%12)+"월";
                    } else if ( pop.is_use == "S" ){
                        pop.available_career = diff * 0.5;
                        pop.available_career_txt = Math.floor(pop.available_career/12)+"년 " + (pop.available_career%12)+"월";
                    }
                }
            },

            doSubmit: () => {
                let params = {
                    idx   : pop.idx,
                    ctype : pop.ctype,
                    title : pop.title,
                    sdate : pop.sdate,
                    edate : pop.edate,
                    period : pop.period,
                    is_use : pop.is_use,
                    available_career : pop.available_career,
                    position : pop.position,
                    maintask : pop.maintask,
                    etc : pop.etc,
                };

                if( params.ctype == "" ){
                    // alert("외/내부 여부를 선택하세요.");
                    alert("필수 항목을 선택해주세요.\n::외/내부 여부")
                    return;
                }
                if( params.title == "" ){
                    // alert("회사명을 입력하세요.");
                    alert("필수 항목을 선택해주세요.\n::회사명")
                    return;
                }
                if( params.sdate == "" ){
                    // alert("입사일을 입력하세요.");
                    alert("필수 항목을 선택해주세요.\n::입사일")
                    return;
                }
                if( params.ctype != "" && params.edate == "" ){
                    // alert("퇴사일을 입력하세요.");
                    alert("필수 항목을 선택해주세요.\n::퇴사일")
                    return;
                }
                if( params.is_use == "" ){
                    // alert("경력인정여부를 선택하세요.");
                    alert("필수 항목을 선택해주세요.\n::경력인정여부")
                    return;
                }
                if( params.position == "" ){
                    // alert("직위를 입력하세요.");
                    alert("필수 항목을 선택해주세요.\n::직위")
                    return;
                }
                if( params.maintask == "" ){
                    // alert("담당업무를 입력하세요.");
                    alert("필수 항목을 선택해주세요.\n::담당업무")
                    return;
                }
                axios.post("/rest/mypims/addCareer", params).then((res) => {
                    if( res.data.err == 0 ){
                        alert("등록되었습니다.");
                        pop.onClose();
                    } else {
                        alert(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        watch(() => pop.sdate, (nVal, oVal) => {
            if(nVal != oVal) {
                pop.doCalculate();
            }
        });

        watch(() => pop.edate, (nVal, oVal) => {
            if(nVal != oVal) {
                pop.doCalculate();
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx = props.idx;
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>